import { AnyObject, Injectable, IResponse, ResourceActionFailed } from '@movecloser/front-core'

import { GraphQLConnector } from '../../../../contexts'
import { resolveFromStatus } from '../../../../backoffice/shared/exceptions/connector-errors'

import { INewsletterService } from './newsletter.contracts'
import { FormOptions } from '../selenaForms'

@Injectable()
export class NewsletterService implements INewsletterService {
  private readonly connector: GraphQLConnector

  constructor (connector: GraphQLConnector) {
    this.connector = connector
  }

  public async subscribe (email: string): Promise<void> {
    const response: IResponse = await this.connector.call('newsletter', { email })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public async subscribeForm (form: AnyObject): Promise<void> {
    const response: IResponse = await this.connector.call('newsletter', { ...form })

    if (!response.isSuccessful() && response.errors) {
      throw new ResourceActionFailed(
        response.errors[0].message,
        resolveFromStatus(response),
        {}
      )
    }
  }

  public getOccupations (): FormOptions {
    return {
      type: [
        {
          label: 'modules.Newsletter.Extended.form.occupation.options.1',
          value: 'prace ociepleniowe'
        },
        {
          label: 'modules.Newsletter.Extended.form.occupation.options.2',
          value: 'prace murarskie'
        },
        {
          label: 'modules.Newsletter.Extended.form.occupation.options.3',
          value: 'montaż okien i drzwi'
        }
      ]
    }
  }
}
