// Copyright © 2022 Move Closer

import VueI18n from 'vue-i18n'
import VueRouter, { RouteConfig } from 'vue-router'
import { Bootstrapper, Container, IConfiguration, ResourceActionFailed, StoreStack } from '@movecloser/front-core'
import { ModuleTree } from 'vuex'

import { applyPatches } from '@/bootstrap/patcher'
import { config } from '@config/index'
import { getEntryPointFromContext } from '@support/entry-point'
import { ISetupService, SetupServiceType } from '@service/setup'
import { Context, ISiteService, logger, SiteServiceType } from '@core'
import { State, Store } from '@contract/store'

import { createI18n, FALLBACK_LOCALE } from './i18n'
import { createRouter } from './router'
import { createStore } from './store'
import { decorateStaticRoutes } from './static-routes'
import { ISessionService, loadSessionCookie, SessionServiceType } from '@/shared/core/src/front/auth/services'
import { CartServiceType, ICartService } from '@/shared/core/src/front/checkout/services/cart'

export interface BootedApp {
  configuration: IConfiguration
  container: Container
  i18n: VueI18n
  router: VueRouter
  store: Store
}

/**
 * Creates the application instance.
 *
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (edited)
 */

export async function createApp (context: Context): Promise<BootedApp> {
  let locale = FALLBACK_LOCALE
  const bootstrapper = new Bootstrapper(config)
  await bootstrapper.boot()

  const configuration: IConfiguration = bootstrapper.getConfiguration()
  const container: Container = bootstrapper.getContainer()

  const storeStack: StoreStack = bootstrapper.getStoreStack()
  const store = createStore(storeStack as ModuleTree<State>)

  if (context.isClient) {
    await applyPatches(container, configuration)
  }

  const session: ISessionService = container.get(SessionServiceType)
  session.setSessionToken(loadSessionCookie(context))

  const cartService: ICartService = container.get(CartServiceType)
  cartService.loadCartId(context)

  const setupService: ISetupService = container.get(SetupServiceType)
  const siteService: ISiteService = container.get(SiteServiceType)
  const entryPoint = getEntryPointFromContext(context)
  if (entryPoint) {
    try {
      await setupService.initApp(entryPoint, context.isClient)
      await store.dispatch('setMaintenanceMode', setupService.isMaintenanceMode)
      locale = setupService.currentLocale.toLowerCase()

      // Redirect to lang depending on browser language

      const botPattern = '(googlebot|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
      const re = new RegExp(botPattern, 'i')
      let userAgent: string
      if (context.isServer) {
        userAgent = context.ssr?.req.headers['user-agent'] || ''
      } else {
        userAgent = navigator.userAgent || ''
      }
      if (!re.test(userAgent)) {
        let navLang: string = 'EN'
        let xLang: string | string[] = ''
        if (context.isServer) {
          xLang = context.ssr?.req.headers['X-Lang'] || ''
          navLang = context.ssr?.req.headers['accept-language'] || 'EN'
          if (xLang === '') {
            context.res?.setHeader('X-Lang', navLang.split('-')[0].toUpperCase())
          }
        } else {
          document.cookie.indexOf('X-Lang') > -1 && (xLang = document.cookie.split('X-Lang=')[1].split(';')[0])
          navLang = window.navigator.language || 'EN'
          navLang = navLang.split('-')[0].toUpperCase()
          if (xLang === '') {
            document.cookie = `X-Lang=${navLang};path=/`
          }
        }

        if (!context.isServer && xLang === '') {
          const site = siteService.getSites()
            .find(site => site.locale === navLang && site.domain === siteService.getActiveSite().domain)

          if (site) {
            const siteUrl = site.address + siteService.getSiteBasePath(site)
            const activeSiteUrl = siteService.getActiveSiteAddress() + siteService.getActiveSiteBasePath()

            console.info('[locale]', { navLang, activeSiteUrl, siteUrl })
            if (siteUrl !== activeSiteUrl) {
              console.warn(`[locale] locale mismatch, redirecting from ${activeSiteUrl} to ${siteUrl}`)
              window.location.replace(siteUrl)
            }
          }
        }
      }
      // EOF Redirect to lang depending on browser language
    } catch (error) {
      if (error instanceof ResourceActionFailed && error.status === 503) {
        await store.dispatch('setMaintenanceMode', setupService.isMaintenanceMode)
      } else {
        logger(error, 'error')
        throw error
      }
    }
  }

  const routesStack: RouteConfig[] = decorateStaticRoutes(
    bootstrapper.getRoutesStack() as RouteConfig[],
    setupService.staticRouting
  )
  const router = createRouter(routesStack, container)
  const i18n = await createI18n(locale)

  return { configuration, container, i18n, router, store }
}
