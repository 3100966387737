



















import { Component, Mixins, Provide } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import { DrawerWrapper, Inject, IS_MOBILE_PROVIDER_KEY, ISiteService, SiteServiceType } from '@core'
import { ModalWrapper } from '@/shared/core/src/front/shared/organisms/ModalWrapper'
import { CoreMixin } from '@/shared/core/src/front/shared/mixins'

import { ErrorDetails } from '@component/ErrorDetails.vue'
import { getHtmlLangAttrValue } from '@support/i18n'
import { isLocalEnv } from '@support/env'

import { HasTheme } from '@module/content/shared/HasTheme.mixin.vue'

/**
 * @author Łukasz Sitnicki <lukasz.sitnicki@movecloser.pl>
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<App>({
  name: 'App',
  components: { DrawerWrapper, ErrorDetails, ModalWrapper },
  metaInfo (): MetaInfo {
    const settings = this.siteService.getProperty('settings')
    let GTM = process.env.VUE_APP_USER_COM_API_KEY
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (settings !== null && settings.gtm) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      GTM = settings.gtm || ''
    }

    return {
      htmlAttrs: {
        lang: getHtmlLangAttrValue(this.$i18n.locale)
      },
      link: [
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '32x32',
          href: `${process.env.BASE_URL}img/favicons/favicon-32x32.png`
        },
        {
          rel: 'icon',
          type: 'image/png',
          sizes: '16x16',
          href: `${process.env.BASE_URL}img/favicons/favicon-16x16.png`
        },
        {
          rel: 'icon',
          href: `${process.env.BASE_URL}img/favicons/favicon.ico`
        },
        {
          rel: 'manifest',
          href: `${process.env.BASE_URL}site.webmanifest`
        },
        {
          rel: 'mask-icon',
          href: `${process.env.BASE_URL}img/favicons/safari-pinned-tab.svg`,
          color: '#000000'
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `${process.env.BASE_URL}img/favicons/apple-touch-icon.png`
        },
        {
          rel: 'preconnect',
          href: process.env.VUE_APP_HTTP_API_BASEURL
        }
      ],
      titleTemplate: `%s | ${this.siteName}`,
      meta: [
        ...this.siteService.getActiveSiteMeta(),
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, shrink-to-fit=no'
        },
        {
          name: 'theme-color',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileColor',
          content: '#ffffff'
        },
        {
          name: 'msapplication-TileImage',
          content: `${process.env.BASE_URL}img/favicons/mstile-144x144.png`
        },
        {
          name: 'apple-mobile-web-app-title',
          content: this.siteName
        },
        {
          name: 'application-name',
          content: this.siteName
        }
      ],
      script: this.checkLighthouseHeaders ? [] : [
        ...(process.env.VUE_APP_USER_COM_ENDPOINT ? [
          {
            once: true,
            async: false,
            defer: false,
            src: process.env.VUE_APP_USER_COM_ENDPOINT
          }
        ] : []),
        ...(process.env.VUE_APP_USER_COM_API_KEY ? [
          {
            once: true,
            vmid: 'user-container',
            async: false,
            defer: false,
            innerHTML: `window.civchat = { apiKey: "${process.env.VUE_APP_USER_COM_API_KEY}" }`
          }
        ] : []),
        ...(GTM ? [
          {
            once: true,
            vmid: 'gtm-container',
            async: true,
            defer: true,
            innerHTML: `
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
            'event': 'info'
          });
          dataLayer.push({
            originalLocation: document.location.protocol + '//' +
                              document.location.hostname +
                              document.location.pathname +
                              document.location.search
          });
          function gtag(){dataLayer.push(arguments);}
          gtag('consent', 'default', {
            'ad_storage': 'denied',
            'analytics_storage': 'denied',
            'functionality_storage': 'denied',
            'wait_for_update': 2500
          });
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),
          event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'
          ?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
          f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer',
          '${GTM}');`,
            type: 'text/javascript'
          }
        ] : [])
      ],
      __dangerouslyDisableSanitizersByTagID: {
        'user-container': ['innerHTML'],
        'gtm-container': ['innerHTML']
      }
    }
  }
})
export class App extends Mixins(CoreMixin, HasTheme) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  public get checkLighthouseHeaders () {
    return this.$store.getters.getUserAgent.indexOf('Chrome-Lighthouse') > -1
  }

  /**
   * Determines whether there are any errors to display.
   */
  public get hasErrors (): boolean {
    return !isLocalEnv() && this.$errorHandler.error !== null
  }

  /**
   * Determines whether the app is running on a mobile phone OR a tablet.
   */
  @Provide(IS_MOBILE_PROVIDER_KEY)
  public isMobile (): boolean {
    return this.$store.getters.isMobile
  }

  /**
   * When un-authenticated.
   */
  public onAuthRequired (): void {
    this.requireAuthentication()
  }

  /**
   * Determines site name.
   */
  public get siteName (): string {
    const activeSite = this.siteService.getActiveSite()
    if (!Object.prototype.hasOwnProperty.call(activeSite, 'name') && !activeSite.name) {
      return ''
    }
    return activeSite.name
  }

  public get env (): string {
    return process.env.VUE_APP_ENV ?? '-'
  }
}

export default App
